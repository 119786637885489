import React, { useEffect } from "react";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top center`;
  });

  return (
    <div className="element">
      <div className="intro">
        <div className="group">
          <div className="overlap-group">
            <img className="scene" alt="Scene" src="/img/scene-1-2x-1.png" />
          </div>
        </div>
        <div className="media-entertainment">Media &amp; Entertainment</div>
        <p className="text-wrapper">Website + CMS + E-commerce</p>
        <p className="div">Enlighten people about issues that matter</p>
        <p className="we-ve-got-issues-is">
          <span className="span">We’ve got issues</span>
          <span className="text-wrapper-2">
            {" "}
            is an online news and magazine platform dwelving deep into design,
            art, work, style and culture. WGI promotes quality of life and
            connects communities and creative professionals from London to New
            York to Mumbai.
          </span>
        </p>
        <div className="group-2">
          <div className="UIX">
            <div className="overlap">
              <div className="text-wrapper-3">UI/UX</div>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <img className="line" alt="Line" src="/img/line-18-1.svg" />
                  <img className="img" alt="Line" src="/img/line-19-1.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className="WD">
            <div className="overlap-2">
              <div className="text-wrapper-4">Web Development</div>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <img className="line" alt="Line" src="/img/line-18.svg" />
                  <img className="img" alt="Line" src="/img/line-19.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-5">Scope of Work</div>
        </div>
        <img className="logo" alt="Logo" src="/img/logo.png" />
      </div>
      <div className="screens">
        <div className="laptop">
          <div className="mask-group-wrapper">
            <img
              className="mask-group"
              alt="Mask group"
              src="/img/mask-group-1.png"
            />
          </div>
        </div>
        <div className="mobile" />
      </div>
      <img
        className="dribbble-shot-HD"
        alt="Dribbble shot HD"
        src="/img/dribbble-shot-hd-1.png"
      />
      <img className="frame" alt="Frame" src="/img/frame-89.svg" />
    </div>
  );
};
